<template>
  <div style="width: 100vw; height: 100vh">
    <BaseHeaderBar
      title=""
      :showCustomerService="false"
      :showBackButton="true"
    />
    <iframe
      id="iframe"
      src="https://sdk.thailand.accuauth.com/silent-liveness-h5/index.html"
      allow="camera;microphone"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
import request from '@/utils/request.js'
import axios from 'axios'
import Compressor from 'compressorjs'
let iframe = null
function iframeLoad () {
  const obj = { language: 'id', region: 'indonesia' }
  iframe.contentWindow.postMessage({ code: 200, info: JSON.stringify(obj) }, '*')
}
export default {
  data () {
    return {
      fun: () => { }
    }
  },
  created () {
  },
  mounted () {
    iframe = document.getElementById('iframe')
    if (!iframe.addEventListener) {
      iframe.attachEvent('onload', iframeLoad)
    }
    iframe.addEventListener('load', iframeLoad, true)
    const thas = this
    addEvent(window, 'message', identifySuccess)
    async function identifySuccess (e) {
      const data = e.data
      // 识别成功
      if (data.code === 200) {
        const { imageId, base64 } = data.info
        const file = thas.base64ToFile(base64)
        thas.compressImage(file, result => {
          thas.uploadImg(result).then(res => {
            const postData = {
              portraitUrl: res,
              imageId: imageId
            }
            request.post('/portraitSubmit', postData).then(res => {
              thas.$router.back()
            })
          })
        })
      }
    }
    this.fun = identifySuccess
    function addEvent (obj, trigger, fun) {
      if (obj.addEventListener) obj.addEventListener(trigger, fun, false)
      else if (obj.attachEvent) obj.attachEvent('on' + trigger, fun)
      else obj['on' + trigger] = fun
    }
  },
  beforeDestroy () {
    window.removeEventListener('message', this.fun, false)
    window.removeEventListener('onmessage', this.fun)
    iframe.removeEventListener('onload', iframeLoad)
    iframe.removeEventListener('load', iframeLoad, true)
  },
  methods: {
    base64ToFile (base64String) {
      // 将base64的数据部分提取出来
      const arr = base64String.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      // 将Uint8Array转换为Blob对象
      const blob = new Blob([u8arr], { type: mime })

      // 创建File对象
      const file = new File([blob], 'image.png', { type: mime })

      return file
    },
    // 压缩图片
    compressImage (file, success) {
      let quality = 1
      const size = file.size
      const baseQuality = 1024 * 1024
      if (size < baseQuality) { // 小于1M
        quality = 0.8
      } else if (size < 5 * baseQuality) { // 小于5M
        quality = 0.4
      } else if (size < 10 * baseQuality) { // 小于10M
        quality = 0.1
      } else { // 大于10M
        quality = 0
      }
      new Compressor(file, {
        quality,
        success
      })
    },
    getUploadParams () {
      return request.post('updrloadImgToken')
    },
    // 上传图片到阿里云
    uploadImg (file) {
      return new Promise((resolve, reject) => {
        this.getUploadParams().then(res => {
          const key = 'web/' + Date.now() + this.randomString(10) + '-' + file.name
          const formData = new FormData()
          formData.append('OSSAccessKeyId', 'LTAI5tD9wrLfvn8sEpRLfpbw')
          formData.append('key', key)
          formData.append('policy', res.podrlicy)
          formData.append('signature', res.sidrgnature)
          formData.append('file', file)
          formData.append('success_action_status', 200)
          axios.post('https://inphotos.oss-ap-southeast-1.aliyuncs.com', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(() => resolve(`https://inphotos.oss-ap-southeast-1.aliyuncs.com/${key}`))
            .catch(error => {
              reject(error)
            })
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 生成随机图片名
    randomString (len = 32) {
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const maxPos = chars.length
      let pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    }
  }
}
</script>
<style scoped lang='less'>
</style>
